$family-primary: 'Raleway', BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;

$family-secondary:	'Crimson Text',	BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;

$body-background-color: #2f1d21;
$link: #fff;
$link-hover: darken($link, 10%);
$primary: $link;
$widescreen: 1400px;

$navbar-background-color: $body-background-color;
$navbar-item-color: #fff;
$navbar-item-hover-background-color: lighten($body-background-color, 10%);
$navbar-item-hover-color: $navbar-item-color;
$navbar-height: 4.5rem;
