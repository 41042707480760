
.hero.has-stage-image {
  background: url('images/Himmelsrichtungen_rot.jpg');
  //background: url('images/UlrikeSorge_entw1_inrot.png');
  background-size: cover;
  background-repeat: no-repeat;

  @include desktop {
    min-height: calc(100vh - 6.5rem);
    background-position-x: 19.2vw;
    //background-size: 80.8vw;
  }

  @include widescreen {
    min-height: calc(100vh - 6.5rem);
    background-position-x: 19.2vw;
    background-size: 80.8vw 100%;
  }

  @include until($desktop) {
    background-position-x: 62.5rem;
    margin-top: 7rem;
    min-height: calc(100vh - 7rem);
  }

  @include until($tablet) {
    background-position-x: 31.5%;
    margin-top: 6.5rem;
    min-height: calc(100vh - 6.5rem);
  }
}

.navbar .navbar-item {
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 900;
}

.logo {
  border: 2px solid $white;
  position: absolute;
  font-size: 2.3rem;

  @include from($tablet) {
    font-size: 2.5rem;
  }

  @include until($desktop) {
    padding: 0.2rem 0.5rem;
    top: 0.6rem;
    width: 95vw;
    left: 2.5vw;
  }

  @include desktop {
    padding: 0.4rem 0.8rem;
    border: 0.3vw solid $white;
    width: 33vw;
    left: 1.9rem;
    top: 4.5rem;
    font-size: 2.6rem;
  }

  @include widescreen {
    border: 0.25vw solid $white;
    width: 33vw;
    left: 2.5vw;
    font-size: 4rem;
  }

  font-family: $family-primary;
  color: $white;
  text-transform: uppercase;

  h1 {
    line-height: 1;
    small {
      font-weight: 300;
    }
    span {
      font-weight: 900;
    }
  }
}

.addresses {
  color: $white;
  font-family: $family-secondary;
  line-height: 1.1;

  position: absolute;

  font-size: 0.95rem;
  border-top: 2px solid $white;
  border-bottom: 2px solid $white;
  padding: 1.3rem;
  bottom: 16.6vh;
  width: 100%;
  background: $body-background-color;

  @include tablet {
    font-size: 0.9rem;
    width: 33vw;
  }

  @include desktop {
    font-size: 1.0rem;
    background: transparent;
    border-top: 0.2vw solid $white;
    border-bottom: 0.2vw solid $white;
    padding: 1.2rem 0.9rem;
    width: 33vw;
    left: 1.9rem;
    bottom: 5vh;
  }

  @include widescreen {
    font-size: 1.2rem;
    border-top: 0.2vw solid $white;
    border-bottom: 0.2vw solid $white;
    padding: 1.6rem;
    width: 33vw;
    left: 2.5vw;
    bottom: 5vh;
  }

  .address-postal {
    font-weight: 600;
    font-style: normal;
    margin-bottom: 1rem;
  }

  .address-contact {
    font-weight: 600;
    font-style: normal;
  }

  .address-link {
    position: absolute;
    bottom: -1.5rem;
    right: 1rem;
    text-transform: uppercase;
    font-weight: 900;
    font-family: $family-primary;
  }
}

.impressum {
  background: transparentize(lighten($body-background-color, 10%), 0.4);
  color: $white;
  padding: 1rem;
  font-size: 0.9rem;
  position: absolute;

  a {
    word-break: break-all;
  }

  h3, h4 {
    color: $white;
  }

  top: 88vh;

  @include desktop {
    font-size: 1rem;
    position: absolute;
    left: 45vw;
    top: 8rem;
    right: 2rem;
  }
}
